import {HomeIcon, IntegrityCheckIcon} from '../../../components/core/icons';
import {UserApplicationScopes} from '../../../interfaces/applications';

// eslint-disable-next-line import/prefer-default-export
export const getTabsData = (scopes: Partial<UserApplicationScopes>) => {
    let tabs = [
        {
            id: 'home',
            title: 'Home',
            icon: HomeIcon,
            to: '/',
        },
    ];

    if (scopes?.videoYoutubeCheck?.read) {
        tabs = [
            ...tabs, {
                id: 'content-integrity',
                title: 'Content integrity check',
                icon: IntegrityCheckIcon,
                to: '/content-integrity',
            },
        ];
    }

    return tabs;
};
