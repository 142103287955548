import {useReactiveVar} from '@apollo/client';
import {Tabs} from '../components/core';
import {Layout} from '../components/layout';
// import Notifications from '../components/partials/notifications/notifications';
import {getTabsData} from '../configs/pages/home/tabs';
import * as styles from './index.module.css';
import {accountVar} from '../helpers/graphql/variables';

const IndexPage = () => {
    const account = useReactiveVar(accountVar);
    const accountPermissions = account ? account?.permissions : {};

    return (
        <Layout title="Home center">
            <Tabs
                data={getTabsData(accountPermissions)}
            />
            <div className={styles.wrapper}>
                {/* <Notifications/> */}
                <h2>Welcome to IX, </h2>
                <p>
                    your LEGO YouTube portal, that provides automated data updates and information<br/>
                    for global/regional/local teams across relevant functional areas.
                </p>
                <p>See menu on the left for application(s).</p>
                <p>In case of further questions or support, please contact <a href="mailto:ix@digitalvision.cz">ix@digitalvision.cz</a></p>
            </div>
        </Layout>
    );
};

export default IndexPage;
